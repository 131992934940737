import { logOut, signIn } from "../Redux/Actions/authActions";
import { auth } from "./FirebaseConfig";


const listenForAuthChanges = (dispatch) => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        dispatch(signIn(user.uid));
      } else {
        // User is signed out
        dispatch(logOut());
      }
    });
  };

  export default listenForAuthChanges