import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientList } from "../../../Redux/Actions/clientActions";
import InfoBox from "../../MainDashboard/Components/InfoBox";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";

const ClientRow = (user) => (
  <div className="clientRow">
    <p>{user.uid}</p>
    <p>{user.bookingCount}</p>
    <p>{user.dateJoined}</p>
    </div>
);

export default function ClientsMainView() {
    const state = useSelector((state) => state.clients);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleBackToHome = async () => {
      navigate("/");
    };
    useEffect(() => {
        dispatch(fetchClientList());
      }, [dispatch]);
    return <div className="mainDashboardView">
        <div className="dashboardHeader">
        <h1 className="dashboardTitle">Clients</h1>
        </div>
        {state.totalClients === 0 ? (
           <div className="noClientsMessage">
           <p>You currently have no clients. Don't worry, sharing your referral code with potential customers can help you grow your client base. Keep sharing and watch your list of referred clients grow!</p>
           <div className='signInButtonBox'>
           <button className='signInButton' onClick={handleBackToHome}>Back to Links</button>
           </div>
         </div>
        ) : (
          <div>
        <div className="overviewBox">
        <div className="row">
        <InfoBox title="TOTAL" value={state.totalClients} />
        <div className="dividerVertical" />
        <div className="dividerVertical" />
        <InfoBox title="LAST 7 DAYS" value={state.pastWeekClients} />
      </div>
      </div>
      {state.clients.length === 0 ? (
        <div className="loadScreen">
        <FadeLoader margin={-7} height={10} width={4} speedMultiplier={1} color={"#00000099"}/>
        </div>
      ) : (
        <>
      <div className="clientRowKey"><p>UID</p><p>Bookings</p><p>Date Joined</p></div>
        <div className="clientSection">
        {state.clients.map((user, index) => (
          <div key={user.uid}>
          <ClientRow key={user.uid} uid={user.uid} dateJoined={user.dateJoined} bookingCount={user.bookingCount} />
          {index !== state.clients.length -1 && <><div className="dividerHorizontal" /><div className="dividerHorizontal" /></>}
          </div>
        ))}
        </div>
        </>
        )}
        </div>
        )}
    </div>
}