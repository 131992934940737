import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../Redux/Actions/authActions';

export default function TestAccount() {
    const state = useSelector((state) => state.auth);
    const dispatch = useDispatch();
  return (
    <div className="formSection">
        <p>Is signed in: {state.signedIn ? "Yes" : "No"}</p>
        {state.signedIn && (
        <>
        <div className="dividerHorizontal" />
        <p>Name: {state.fullName}</p>
        <div className="dividerHorizontal" />
        <p>Number: {state.phoneNumber}</p>
        <div className="dividerHorizontal" />
        <p>Referral Code: {state.referralCode}</p>
        <div className="dividerHorizontal" />
        <p>Initials: {state.initials}</p>
        </>
        )}
        <div className="dividerHorizontal" />
        <button onClick={() => {dispatch(logOut())}}>Sign out</button>
        </div>
  );
};