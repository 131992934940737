import { serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import combinationMark from '../../Images/CRWDCombinationMark.png'
import { submitApplication } from '../../Redux/Actions/authActions';

export default function ApplyView() {
    const [email, setEmail] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [fullName, setfullName] = useState("");
    const [info, setInfo] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    const handleEmail = (event) => {
       setEmail(event.target.value);
    };
    const handleName = (event) => {
        setfullName(event.target.value);
     };
     const handleNumber = (event) => {
        setphoneNumber(event.target.value);
     };
    const handleInfo = (event) => {
        setInfo(event.target.value);
     };

     const handleSubmit = async () => {
      const data = {
        fullname: fullName,
        email: email,
        phoneNumber: phoneNumber,
        info: info,
        dateApplied: serverTimestamp()
    };
    try {
      await submitApplication(data);
      setSubmitted(true);
    } catch (error) {
      setError("There was an issue submitting your application, please try again");
    }
      };

  return (
    <div className='signInView'>
        <img className='signInLogo' src={combinationMark} alt="logo"/>
        {submitted ? (
          <div>
            <div className="application-submitted-view">
            <span className="material-symbols-outlined">check_circle</span>
          <h3>Application Submitted</h3>
          <p>Thank you for applying to be a partner. We will get back to you as soon as possible.</p>
        </div>
          </div>
        ) : (
          <div>
          <h3 className='codeTitle'>Apply to be a Partner</h3>
          <div className="signInInputs">
          <input
                type="text"
                value={fullName}
                placeholder="Full Name"
                onChange={handleName}
              />
              <div className="dividerHorizontal" />
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={handleEmail}
              />
              <div className="dividerHorizontal" />
              <input
                type="text"
                value={phoneNumber}
                placeholder="Phone Number"
                onChange={handleNumber}
              />
              <div className="dividerHorizontal" />
              <textarea
                type="text"
                value={info}
                placeholder="Tell us about yourself and how you can contribute to our partner program. Do you work in hospitality, entertainment, or another industry? Are you a social media influencer, blogger, or content creator? Share your experience and skills with us, including any relevant social media profiles, blogs, or websites. We're looking for partners who can help us reach new audiences and provide valuable experiences for our customers."
                rows={12}
                onChange={handleInfo}
              />
              </div>
              {error !== "" && <p className='errorMessage'>{error}</p>}
              <div className='signInButtonBox'>
              <button className='signInButton' onClick={handleSubmit}>Submit</button>
              </div>
              </div>
        )}
        </div>
  );
};