import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ClientsMainView from '../../Views/DetailViews/ClientViews/ClientsMainView';
import MainDashboardView from '../../Views/MainDashboard/MainDashboardView';


function SignedInRouter() {
  return (
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainDashboardView />} />
        <Route path='/clients' element={<ClientsMainView />} />
      </Routes>
      </BrowserRouter>
  );
}

export default SignedInRouter;
