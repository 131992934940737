import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ApplyView from '../../Views/AuthViews/ApplyView';
import RegisterView from '../../Views/AuthViews/RegisterView';
import SignInView from '../../Views/AuthViews/SignInView';
import SignUpView from '../../Views/AuthViews/SignUpView';


function SignedOutRouter() {
  return (
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<SignInView />} />
        <Route path='/signin' element={<SignInView />} />
        <Route path='/register' element={<RegisterView />} />
        <Route path='/apply' element={<ApplyView />} />
        <Route path='/signup' element={<SignUpView />} />
      </Routes>
      </BrowserRouter>
  );
}

export default SignedOutRouter;
