import { FETCH_ACCOUNT_DETAILS, SIGN_OUT, CHECK_REGISTRATION_CODE } from "../ActionTypes/authActionTypes";

const initialState = {
    signedIn: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACCOUNT_DETAILS:
            return {
                ...state,
                signedIn: true,
                fullName: action.payload.fullname,
                phoneNumber: action.payload.phoneNumber,
                referralCode: action.payload.referralID,
                initials: action.payload.fullname.charAt(0),
                referralLink: action.payload.referralLink,
                qrCodeUrl: action.payload.qrCodeUrlPNG,
            };
        case SIGN_OUT:
            return {
                signedIn: false,
                fullName: "",
                phoneNumber: "",
                referralCode: "",
                initials: "",
            };
        case CHECK_REGISTRATION_CODE:
            return {
                ...state,
                validCode: action.payload.validCode,
                accountType: action.payload.accountType,
                alert: action.payload.alert,
                registrationCode: action.payload.registrationCode,
            };
        default:
             return state
    }
};

export default authReducer