import { collection, doc, getDoc, addDoc, setDoc } from "firebase/firestore";
import { CHECK_REGISTRATION_CODE, FETCH_ACCOUNT_DETAILS, SIGN_OUT } from "../ActionTypes/authActionTypes"
import { auth, db, functions } from "../../ViewModels/FirebaseConfig";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";


async function getUserData(uid) {
    const userDoc = doc(db, "Affiliates/"+uid)
    const docDetails = await getDoc(userDoc)
    const data = docDetails.data()
    return data
}

const signIn = (uid) => async (dispatch) => {
    const payload = await getUserData(uid)
    console.log(payload)
    dispatch({
        type: FETCH_ACCOUNT_DETAILS,
        payload: payload
    });
};

const logOut = () => {
    signOut(auth)
    return {
        type: SIGN_OUT
    };
};

const submitApplication = async (data) => {
    const coll = collection(db, "Affiliates/Applications/Applications");
    const docRef = await addDoc(coll, data);
    return docRef;
  };

const createAccount = async (data) => {
    createUserWithEmailAndPassword(auth, data.email, data.password)
    .then((userCredential) => {
        const user = userCredential.user;
        const docRef = doc(db, "Affiliates", user.uid);
        const userData = {
            fullname: data.fullName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            type: data.type,
            registrationCode: data.code
        }
        setDoc(docRef, userData, { merge: true });
      })
      .catch((error) => {
        console.log(error)
      });
  };

const checkRegistrationCode = (regCode) => async (dispatch) => {
    const checkRegistrationCode = httpsCallable(functions, "checkRegistrationCode");
    const result = await checkRegistrationCode({code: regCode})
    console.log(result.data)
    if (result.data.alert === "validCode"){
        const payload = {
            accountType: result.data.type,
            validCode: true,
            registrationCode: regCode,
        }
        dispatch({
            type: CHECK_REGISTRATION_CODE,
            payload: payload
        })
    } else {
        const payload = {
            validCode: false,
            alert: result.data.alert,
            registrationCode: "",
        }
        dispatch({
            type: CHECK_REGISTRATION_CODE,
            payload: payload
        })
    }
};

export { signIn, logOut, checkRegistrationCode, submitApplication, createAccount };