import { FETCH_CLIENT_LIST, FETCH_TOTAL_BOOKINGS, FETCH_TOTAL_CLIENTS } from "../ActionTypes/clientActionTypes";

const initialState = {
    totalClients: 0,
    pastWeekClients: 0,
    clients: []
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOTAL_CLIENTS:
            return {
                ...state,
                totalClients: action.payload.totalClients,
                totalBookings: action.payload.totalBookings
            };
        case FETCH_CLIENT_LIST:
            return {
                ...state,
                clients: action.payload.clients,
                pastWeekClients: action.payload.pastWeekClients,
                totalClients: action.payload.totalClients
            };
        case FETCH_TOTAL_BOOKINGS:
            return {
                ...state,
                totalBookings: action.payload.totalBookings
            };
        default:
            return state
    }
};

export default clientReducer