import React from 'react';
import { useSelector } from 'react-redux';
import InfoBox from './InfoBox';

export default function OverviewInfoBox() {
  const state = useSelector((state) => state.clients)
  return (
    <div className="overviewBox">
      <div className="row">
        <InfoBox title="TOTAL SIGNUPS" value={state.totalClients} />
        <div className="dividerVertical" />
        <div className="dividerVertical" />
        <InfoBox title="TOTAL BOOKINGS" value={state.totalBookings} />
      </div>
      <div className="dividerHorizontal" />
      <div className="row">
        <InfoBox title="PAST WEEK EARNINGS" value="528 AED" />
        <div className="dividerVertical" />
        <div className="dividerVertical" />
        <InfoBox title="LIFETIME EARNINGS" value="12 468 AED" />
      </div>
    </div>
  );
};
