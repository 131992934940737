import { useSelector } from 'react-redux';
import './App.css';
import SignedInRouter from './ViewModels/Routers/SignedInRouter';
import SignedOutRouter from './ViewModels/Routers/SignedOutRouter';


function App() {
  const state = useSelector((state) => state.auth);
  return (
    <div className="App">
      {state.signedIn ? (
      <SignedInRouter/>
      ) : (<SignedOutRouter/>)}
    </div>
  );
}

export default App;
