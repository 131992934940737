import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchClientCount } from "../../Redux/Actions/clientActions";
import OverviewInfoBox from "./Components/OverviewInfoBox";
import TestAccount from "./Components/TestAccount";

export default function MainDashboardView() {
    const state = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchClientCount(state.referralCode));
      }, [state.referralCode, dispatch]);
    console.log(state.signedIn)
    return <div className="mainDashboardView">
        <div className="dashboardHeader">
        <h1 className="dashboardTitle">Dashboard</h1>
        <p className="accountButton">{state.initials}</p>
        </div>
        <OverviewInfoBox/>
        <div className="formSectionLinks">
            <a className="referralLink" href={state.referralLink}>{state.referralLink}</a>
        <div className="dividerHorizontal" />
        <div className="dividerHorizontal" />
        <a className="QRLink" href={state.qrCodeUrl} target="_blank" rel="noopener noreferrer">View QR Code</a>
        </div>
        <div className="formSection">
        <a className="navigationLink" href="/"><span className="material-symbols-outlined">group</span>Overview</a>
        <div className="dividerHorizontal" />
        <a href="/">Venues</a>
        <div className="dividerHorizontal" />
        <Link to="/clients">Clients</Link>
        <div className="dividerHorizontal" />
        <a href="/">Earnings</a>
        <div className="dividerHorizontal" />
        </div>
        <TestAccount/>
    </div>
}