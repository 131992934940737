import { collection, collectionGroup, getCountFromServer, getDocs, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "../../ViewModels/FirebaseConfig";
import { FETCH_CLIENT_LIST, FETCH_TOTAL_CLIENTS } from "../ActionTypes/clientActionTypes";


async function getClientCount() {
    const uid = auth.currentUser.uid
    const clientsCol = collection(db, "Affiliates/"+uid+"/referredUsers")
    const snapshot = await getCountFromServer(clientsCol);
    return snapshot.data().count
}

async function getBookingCount() {
    const uid = auth.currentUser.uid
    const clientsCol = collection(db, "Affiliates/"+uid+"/referredUsers");
    const snapshot = await getDocs(clientsCol);
    const clientUIDs = snapshot.docs.map(doc => doc.id);
    let totalBookings = 0;
    for (const clientUID of clientUIDs) {
        const bookingCount = await getClientBookingCount(clientUID);
        totalBookings += bookingCount;
    }
    return totalBookings
}

async function getWeekClientCount() {
    const uid = auth.currentUser.uid
    const clientsCol = collection(db, "Affiliates/"+uid+"/referredUsers")
    const now = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(now.getDate() - 7);
    const dateQuery = query(clientsCol, where("dateJoined", ">=", sevenDaysAgo));
    const snapshot = await getCountFromServer(dateQuery);
    return snapshot.data().count
}

async function getClientBookingCount(clientUID) {
    const bookCol = collectionGroup(db, "Bookings");
    const q = query(bookCol, where("peopleID", "array-contains", clientUID))
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}

async function getAllClients() {
    const uid = auth.currentUser.uid
    const clientsCol = collection(db, "Affiliates/"+uid+"/referredUsers");
    const orderedQuery = query(clientsCol, orderBy("dateJoined", "desc"));
    const snapshot = await getDocs(orderedQuery)
    const clients = [];
    for (const doc of snapshot.docs) {
        const data = doc.data()
        const dateJoined = data.dateJoined.toDate()
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = dateJoined.toLocaleDateString(options);
        const bookingCount = await getClientBookingCount(doc.id);
        console.log(bookingCount)
        const client = {
            uid: doc.id,
            dateJoined: formattedDate,
            bookingCount: bookingCount,
        }
        clients.push(client);
    };
    return clients
}

const fetchClientCount = () => async (dispatch) => {
    const clientCount = await getClientCount()
    const bookingCount = await getBookingCount()
    const payload = {
        totalClients: clientCount,
        totalBookings: bookingCount
    }
    console.log(payload)
    dispatch({
        type: FETCH_TOTAL_CLIENTS,
        payload: payload
    });
};

const fetchClientList = () => async (dispatch) => {
    const clients = await getAllClients()
    const pastWeekClients = await getWeekClientCount()
    const clientCount = await getClientCount()
    const payload = {
        clients: clients,
        pastWeekClients: pastWeekClients,
        totalClients: clientCount,
    }
    dispatch({
        type: FETCH_CLIENT_LIST,
        payload: payload
    });
};

export { fetchClientCount, fetchClientList };