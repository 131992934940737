import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { auth } from '../../ViewModels/FirebaseConfig';
import combinationMark from '../../Images/CRWDCombinationMark.png'
import { Link } from 'react-router-dom';

export default function SignInView() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmail = (event) => {
       setEmail(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
     };

     const handleSignIn = () => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            console.log('User signed in:', user);
            setEmail("")
            setPassword("")
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error('Error signing in:', errorCode, errorMessage);
          });
      };

  return (
    <div className='signInView'>
        <img className='signInLogo' src={combinationMark} alt="logo"/>
    <div className="signInInputs">
        <input
          type="text"
          value={email}
          placeholder="Email"
          onChange={handleEmail}
        />
        <div className="dividerHorizontal" />
        <input
          type="text"
          value={password}
          placeholder="Password"
          onChange={handlePassword}
        />
        </div>
        <div className='signInButtonBox'>
        <button className='signInButton' onClick={handleSignIn}>Sign in</button>
        </div>
        <div className="signInOrBlock">
        <div className="dividerHorizontal" />
        <p>or</p>
        <div className="dividerHorizontal" />
        </div>
        <div className='registerButtonBox'>
        <Link className='registerButton' to="/register">Register</Link>
        </div>
        </div>
  );
};