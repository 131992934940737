import React, { useEffect, useState } from 'react';
import combinationMark from '../../Images/CRWDCombinationMark.png'
import { Link, useNavigate } from 'react-router-dom';
import { checkRegistrationCode } from '../../Redux/Actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

export default function RegisterView() {
    const [code, setCode] = useState("");
    const state = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleCode = (event) => {
       setCode(event.target.value);
    };

    const handleRegister = async () => {
        dispatch(checkRegistrationCode(code));
      };

      useEffect(() => {
        if (state.validCode) {
          navigate("/signup");
        }
      }, [state.validCode, navigate]);

  return (
    <div className='signInView'>
        <img className='signInLogo' src={combinationMark} alt="logo"/>
        <h3 className='codeTitle'>Have a registration code?</h3>
    <div className="signInInputs">
    <input
          type="text"
          value={code}
          placeholder="Registration Code"
          onChange={handleCode}
        />
        </div>
        {state.alert !== "" && <p className='errorMessage'>{state.alert}</p>}
        <div className='signInButtonBox'>
        <button className='signInButton' onClick={handleRegister}>Register</button>
        </div>
        <div className="signInOrBlock">
        <div className="dividerHorizontal" />
        <p>or</p>
        <div className="dividerHorizontal" />
        </div>
        <div className='registerButtonBox'>
        <Link className='registerButton' to="/apply">Apply Now</Link>
        </div>
        </div>
  );
};