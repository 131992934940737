import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import combinationMark from '../../Images/CRWDCombinationMark.png'
import { createAccount } from '../../Redux/Actions/authActions';

export default function SignUpView() {
  const state = useSelector((state) => state.auth)
  const navigate = useNavigate()
    const [email, setEmail] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [fullName, setfullName] = useState("");
    const [password, setPassword] = useState("");

    const handleEmail = (event) => {
       setEmail(event.target.value);
    };
    const handleName = (event) => {
        setfullName(event.target.value);
     };
     const handleNumber = (event) => {
        setphoneNumber(event.target.value);
     };
    const handlePassword = (event) => {
        setPassword(event.target.value);
     };

     const handleSignUp = () => {
      const data = {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        code: state.registrationCode,
        type: state.accountType
      }
      createAccount(data)
      navigate("/")
      };

  return (
    <div className='signInView'>
        <img className='signInLogo' src={combinationMark} alt="logo"/>
    <div className="signInInputs">
    <input
          type="text"
          value={fullName}
          placeholder="Full Name"
          onChange={handleName}
        />
        <div className="dividerHorizontal" />
        <input
          type="text"
          value={email}
          placeholder="Email"
          onChange={handleEmail}
        />
        <div className="dividerHorizontal" />
        <input
          type="text"
          value={phoneNumber}
          placeholder="Phone Number"
          onChange={handleNumber}
        />
        <div className="dividerHorizontal" />
        <input
          type="text"
          value={password}
          placeholder="Password"
          onChange={handlePassword}
        />
        </div>
        <div className='signInButtonBox'>
        <button className='signInButton' onClick={handleSignUp}>Create Account</button>
        </div>
        </div>
  );
};