import React from 'react';

const InfoBox = ({ title, value }) => (
  <div className="info-box">
    <div className="info-title">{title}</div>
    <div className="info-padding" />
    <div className="info-value">{value}</div>
  </div>
);

export default InfoBox